import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"
import CTA from "../../components/cta"

export default () => (
  <Layout>

    <SEO title="Website builder and content management system (CMS) for travel companies"
        description="Getlocal website builder and CMS solution is design and built for travel companies"
        pathname="/pricing/website-builder-cms/"
    />
        
    <Hero 
         headerText="Website builder" 
         subHeaderText="Easy to use website builder and CMS solution for travel companies"
            /> 


    <section className="section column is-10 is-offset-1 content-pages">
      <div className="container">
        <div className="content center intro">
            <h2 className="center title is-size-4">Customizable platform to create your website</h2>
            <p className="center">Create a beautiful engaging website using your own brand and design, allow your team to easily manage the content and make changes.</p>
 
            <CTA />
        </div>
      </div>
    </section>



    <section className="section column is-10 is-offset-1 content content-pages">  

       
        <div className="columns is-multiline">
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Highly customizable and powerful content manager (CMS)</h3>
                    <p className="">Our solution is highly customizable and contains a powerful CMS that allows you to create simple and advanced ecommerce sites that integrate with powerful inventory and booking systems.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Designed with your own branding</h3>
                    <p className="">The design and page structures work well across all browsers and devices. They can be modified to fit any brand and design. You are also welcome to use our tried and tested design templates as they come out of the box.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Ultra fast and mobile optimized</h3>
                    <p className="">Our front end is very lightweight and responsive so it works extremely well on mobile devices and tablets. With advanced techniques and smart caching the customers will experience lightning speed, especially on mobile devices. Some of our clients see over 70% of conversions take place on mobile devices.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Robust and scalable hosting</h3>
                    <p className="">The solution is hosted in Amazon’s cloud server (AWS) making it ultra reliable and secure. We make sure to compress images before they are stored on the server and serve through a Content Delivery Network (CDN) so customers from around the world don’t experience slow network connections and loading times.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Create an engaging blog</h3>
                    <p className="">Share your knowledge and expertise through the blog section. A good blog can be a key to drive traffic and increase exposure for your website and business.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Self service to manage bookings</h3>
                    <p className="">Authentication for the customer to manage their bookings, change dates, cancel etc.</p>                   
                </section>
            </div>
        </div>

        <hr />

        <h2 className="center title is-size-4">Online marketing and search engine optimization</h2>
        <p className="center">The solution is highly optimized for search engine ranking and tracking of online marketing.</p>

        <div className="columns is-multiline">
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Search engine optimized</h3>
                    <p className="">The CMS is designed for SEO so all page attributes are customizable, we also expose the product data as json-ld helping search engines understand the products better. </p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Content marketing</h3>
                    <p className="">The CMS also comes with user management and blogging features allowing you to create insightful and engaging content to drive traffic to your website.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Enhanced analytics and conversion tracking</h3>
                    <p className="">STight integration with Google analytics enhanced ecommerce and Facebook campaign tracking. The combination of using a data layer and google tag manager allows for integrations to other tracking tools.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Affiliate tracking</h3>
                    <p className="">Affiliates can be defined with a custom affiliate commission. Once the affiliation ID is used we create a cookie that track all sales for that affiliate. Great for Airbnb hosts and others that want to benefit from recommending your products.</p>                   
                </section>
            </div>
            
        
        </div>


            

    </section>
  </Layout>
)
